$(() => {

  const menuButton = $('#menu-mobile-button');
  const menu = $('#menu-mobile');
  const openCloseMenu = () => {
    if ($('#icon-open').hasClass('hidden')) {
      $('#icon-close').addClass('hidden');
      $('#icon-open').removeClass('hidden');
      menu.addClass('hidden');
    } else {
      $('#icon-close').removeClass('hidden');
      $('#icon-open').addClass('hidden');
      menu.removeClass('hidden');
    }
  };

  menuButton.on('click', openCloseMenu);

  const profileMenuButton = $('#profile-menu-mobile-button');
  const profileMenu = $('#profile-menu-mobile');
  const openCloseProfileMenu = () => {
    if (profileMenu.attr('class').split(/\s+/).includes('hidden')) {
      profileMenu.removeClass('hidden');
    } else {
      profileMenu.addClass('hidden');
    }
  };

  profileMenuButton.on('click', openCloseProfileMenu);

  $(document).click(function(e) 
  { 
    var target = e.target; 
    if (!$(target).is('#profile-menu-mobile-button') && !$(target).parents().is('#profile-menu-mobile-button')){ 
      profileMenu.addClass('hidden'); 
    }
  });
});