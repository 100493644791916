// require('../scss/tailwind.css');
// fontAwesome
require('../scss/stagiaires.scss');

// js
window.$ = window.jQuery = require('jquery');

require('./stagiaires/layout');
require('./utils/common');
require('./utils/audio');

$(() => {
  let intervalHandler = undefined;
  const refreshInterval = () => $('.auto-refresh').each(function () {
    const el = $(this);
    intervalHandler = setInterval(() => {
      $.get($(this).data('href'), (data) => {
        el.html(data);
      });
    }, 5000);
  });

  const fadeToast = (item) => {
    item.addClass('hidden');
  };

  $('.tw-toast-autohide').each(function () {
    const item = $(this);
    setTimeout(() => {
      fadeToast(item);
    }, 3000);
  });

  $('.tw-toast-close').on('click', function () {
    fadeToast($(this).parents('.alert'));
  });

  const togglePassword = $('#password-toggle');

  togglePassword.on('click', () => {
    var type = $('#password').attr('type') === 'password' ? 'text' : 'password';
    $('#password').attr('type', type);
    togglePassword.children('i').toggleClass('fa-eye-slash fa-eye');
  });

  $('div.btn-div').on('click', function (e) {
    if (e.target.tagName === 'A') {
      return;
    }
    window.location.href = $(this).data('href');
  });

  $(document).on('click', '#link-start-modal', function () {
    $('#start-test').attr('href', $(this).data('href'));
    document.getElementById('start_modal').showModal();
    clearInterval(intervalHandler);
    $('#player-audio')[0].play();
  });

  $('#start_modal').on('close', function() {
    $('#player-audio')[0].pause();
    refreshInterval();
  });

  refreshInterval();

  // progress bar
  $('footer > .progress').each(function() {
    const progress  = $(this);
    let seconds     = progress.data('seconds');
    const total     = progress.data('total');

    setInterval(() => {
      if (seconds === 0) {
        window.location.href = window.location.href.replace('?start=true', '');
        return;
      }
      seconds--;
      progress.css('width', `${seconds * 100 / total}%`);
    }, 1000);
  });

  // question-form: submit with ajax post
  $('#question-form button[type=submit]').on('click', function(e) {
    // disable buttons
    $('#question-form button').prop('disabled', true);
    e.preventDefault();
    
    const url = $(this).closest('form').attr('action');
    const value = $(this).attr('value');

    // post
    $.post(url, { answer_id: value }, (response) => {
      if (response.saved) {
        $(this).closest('form').find('button').addClass('btn-outline');
        $(this).removeClass('btn-outline');
        // enable buttons
        $('#question-form button').prop('disabled', false);
      }
    });
    return false;
  });
});
