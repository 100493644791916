$(() => {

  if ($('.audio-player').length < 1) {
    return;
  }

  //Audio
  $('.audio-player').on('click', function() {
    const target  = $(this).data('target');
    const css     = $(this).data('css');

    const audio = $(target)[0];

    if (!audio.paused) {
      $(this).html(`<i class="fal ${css} fa-play"></i>`);
      audio.pause();
    } else {
      $(this).html(`<i class="fal ${css} fa-volume-high"></i>`);
      audio.play();
    }
    audio.onended = () => {
      $(this).html(`<i class="fal ${css} fa-play"></i>`);
      audio.load();
      audio.pause();
    };
    
    return false;
  });
});
